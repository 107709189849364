import { forwardRef, useContext } from "react";
import { useSelector } from "react-redux";
import styles from "./ListTile.css";
import classes from "classnames";
import { useHistory } from "react-router-dom";
import GeneralContext from "../GeneralContext";
import EdenListTile from "@churchofjesuschrist/eden-list-tile";
import { navigate, shouldPreventDefault } from "../SmartLink";
import { selectImagePreferences } from "../../selectors";

const ListTile = forwardRef(({ className, image, onClick, ...props }, ref) => {
    const disableImages = useSelector(selectImagePreferences);
    const { lang } = useContext(GeneralContext);
    const history = useHistory();

    const handleClick = (event) => {
        onClick && onClick(event);

        if (shouldPreventDefault(event)) {
            event.preventDefault();
            navigate(props.href, lang, history);
        }
    };

    return (
        <EdenListTile
            {...props}
            image={!disableImages ? image : undefined}
            className={classes(className, styles.listTile)}
            disableImages={disableImages}
            onClick={handleClick}
            ref={ref}
        />
    );
});
ListTile.displayName = "ListTile";

export default ListTile;
