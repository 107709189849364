// TODO: The below disabled eslint rules were done quickly to get the code to compile. They should be reviewed and re-enabled or corrected as necessary.
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import styles from "../Image.css";
import classes from "classnames";
import handleClickAndEnterKey from "../../../../util/handle-click-and-enter-key";
import { Image as ImageIcon } from "@churchofjesuschrist/eden-icons";
import { Text3, Text4 } from "@churchofjesuschrist/eden-text";

const AssociatedMediaPlaceholder = ({
    alt = "",
    className,
    disableImages,
    handleShow,
    height,
    imageText,
    width,
}) => {
    return disableImages ? (
        <button
            className={styles.isAssociatedMedia}
            onClick={handleClickAndEnterKey(handleShow)}
            onKeyPress={handleClickAndEnterKey(handleShow)}
        >
            <ImageIcon size="1.5rem" />
            <Text3 as="span" className={styles.i18nString}>
                {imageText}
            </Text3>
        </button>
    ) : (
        <figure
            className={classes(className, styles.loadImageItem)}
            onClick={handleClickAndEnterKey(handleShow)}
            onKeyPress={handleClickAndEnterKey(handleShow)}
        >
            <img width={width} height={height} alt="" />
            <div className={styles.container}>
                {className === "icon" ? (
                    <div className={classes(styles.innerFlex)}>
                        <Text4 className={styles.i18nString}>{imageText}</Text4>
                        <Text4 className={styles.title}>{alt}</Text4>
                    </div>
                ) : (
                    <div className={styles.innerFlex}>
                        <ImageIcon size="1.5rem" />
                        <Text3 className={styles.i18nString}>{imageText}</Text3>
                        <Text3 className={styles.title}>{alt}</Text3>
                    </div>
                )}
            </div>
        </figure>
    );
};

export default AssociatedMediaPlaceholder;
