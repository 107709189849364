import { createRef, Component } from "react";
import { useSelector } from "react-redux";
import classes from "classnames";
import styles from "./Image.css";
import AssociatedMediaPlaceholder from "./components/AssociatedMediaPlaceholder";
import DefaultPlaceholder from "./components/DefaultPlaceholder";
import LibraryItemPlaceholder from "./components/LibraryItemPlaceholder";
import { selectImagePreferences } from "../../selectors";
import { useSelectI18nStringById } from "../../../util/custom-hooks";

export class Image extends Component {
    image = createRef();

    state = {
        loaded: false,
        show: typeof window === "undefined", // default to show for server side loading, but then hide by default on client side
    };

    handleShow = () => {
        this.setState({ show: true });
    };

    componentDidMount() {
        const { disableImages } = this.props;

        // set to show:true if disableImages is false
        if (!disableImages) this.setState({ show: true });
    }

    componentDidUpdate(prevProps) {
        const { disableImages } = this.props;

        if (prevProps.disableImages !== disableImages) {
            this.setState({ show: !disableImages, loaded: false });
        }
    }

    onLoad = () => {
        this.setState({ loaded: true });
    };

    render() {
        let {
            alt,
            className,
            disableImages,
            inline,
            isAssociatedMedia,
            isLibraryItem,
            isPDF,
            placeholderClass,
            passedStyle,
            src = "",
            width = 16,
            height = 9,
            selectI18nStringById,
            srcset = this.props.srcSet,
            ...props
        } = this.props;

        const { loaded, show } = this.state;
        const Wrapper = inline ? "span" : "div";
        const placeHolderText = isPDF ? "PDFText" : "imageText";

        return (
            <Wrapper
                ref={this.image}
                className={classes(
                    styles.imageWrapper,
                    show && !loaded && placeholderClass
                )}
                style={passedStyle}
            >
                {(show || loaded) && (
                    <img
                        {...props}
                        alt={alt || ""}
                        className={classes(className, styles.img)}
                        height={height}
                        onLoad={this.onLoad}
                        loading="lazy" // if this is below src/srcSet it doesn't work for some reason
                        src={src}
                        srcSet={srcset}
                        width={width}
                    />
                )}
                {!loaded &&
                    !show &&
                    (isAssociatedMedia ? (
                        <AssociatedMediaPlaceholder
                            alt={alt}
                            className={classes(
                                className,
                                show && styles.loading
                            )}
                            disableImages={disableImages}
                            handleShow={this.handleShow}
                            height={height}
                            imageText={selectI18nStringById(placeHolderText)}
                            inline={inline}
                            width={width}
                            {...props}
                        />
                    ) : isLibraryItem ? (
                        <LibraryItemPlaceholder
                            disableImages={disableImages}
                            inline={inline}
                        />
                    ) : (
                        <DefaultPlaceholder
                            alt={alt}
                            className={classes(
                                className,
                                show && styles.loading
                            )}
                            disableImages={disableImages}
                            handleShow={this.handleShow}
                            height={height}
                            imageText={selectI18nStringById(placeHolderText)}
                            inline={inline}
                            isLibraryItem={isLibraryItem}
                            width={width}
                            {...props}
                        />
                    ))}
            </Wrapper>
        );
    }
}

const ImageContainer = (props) => {
    const disableImages = useSelector(selectImagePreferences);
    const selectI18nStringById = useSelectI18nStringById();

    return (
        <Image
            selectI18nStringById={selectI18nStringById}
            // redux (selectImagePreferences) hasn't fired/populated yet in the first client side render, so we need to check localStorage directly for the performance efficiency we are looking for
            disableImages={
                disableImages || typeof window !== "undefined"
                    ? localStorage.getItem("disableImages") === "true"
                    : undefined
            }
            {...props}
        />
    );
};

ImageContainer.displayName = ImageContainer;

export default ImageContainer;
