import { forwardRef, useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { get } from "@churchofjesuschrist/universal-env";
import GeneralContext from "../GeneralContext";
import {
    createLocation,
    includesAppBase,
    makeRelativeUrl,
} from "../../../util/uri-utils";

const { APP_PATHNAME } = get();

const isInternalCheck = (location = "") => {
    location =
        typeof location === "string" ? createLocation(location) : location;

    let { pathname = APP_PATHNAME, href } = location;

    return includesAppBase(pathname) || /^#/.test(href);
};

export const navigate = (url, lang, history, state) => {
    const { hash, pathname, search } = window.location;
    const location = createLocation(url, state, { lang });
    const isInternal = isInternalCheck(location);
    const isHashChange =
        pathname === location.pathname &&
        hash !== location.hash &&
        search === location.search;

    if (!isInternal) {
        window.open(url, "_blank");
    } else if (isHashChange) {
        window.location.hash = location.hash;
    } else {
        history.push(location);
    }
};

/* A check to decide if the default functionality should be prevented */
export const shouldPreventDefault = (event) => {
    return !(
        event &&
        (event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)
    );
};

const navigateOnClick =
    (url, lang, state, history, onClick = () => null) =>
    (event) => {
        if (event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)
            return;

        onClick(event);

        if (!url || event.defaultPrevented) return;

        event.preventDefault();
        navigate(url, lang, history, state);
    };

const SmartLink = forwardRef(
    ({ children, className, href, onClick, state, to, ...props }, ref) => {
        const history = useHistory();
        const { lang } = useContext(GeneralContext);
        let baseLocation = useLocation();
        let url = href || to;
        let location =
            url &&
            createLocation(url, state, { baseUrl: baseLocation.href, lang });
        let LinkTag =
            location && isInternalCheck(location) ? Link : url ? "a" : "span";

        return (
            <LinkTag
                className={className}
                onClick={navigateOnClick(url, lang, state, history, onClick)}
                to={location}
                href={makeRelativeUrl(url)}
                ref={ref}
                {...props}
            >
                {children}
            </LinkTag>
        );
    }
);

SmartLink.displayName = "SmartLink";

export default SmartLink;
