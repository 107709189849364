import styles from "./eden-headings.css";
import classes from "classnames";
import {
    H1 as EdenH1,
    H2 as EdenH2,
    H3 as EdenH3,
    H4 as EdenH4,
    H5 as EdenH5,
    H6 as EdenH6,
} from "@churchofjesuschrist/eden-headings";

export const Title1 = ({ children, className, ...props }) => (
    <H1
        renderAs="div"
        className={classes(styles.heading, className)}
        {...props}
    >
        {children}
    </H1>
);

export const H1 = ({ children, className, ...props }) => (
    <EdenH1 className={classes(styles.heading, className)} {...props}>
        {children}
    </EdenH1>
);

export const Title2 = ({ children, className, ...props }) => (
    <H2
        renderAs="div"
        className={classes(styles.heading, className)}
        {...props}
    >
        {children}
    </H2>
);

export const H2 = ({ children, className, ...props }) => (
    <EdenH2 className={classes(styles.heading, className)} {...props}>
        {children}
    </EdenH2>
);

export const Title3 = ({ children, className, ...props }) => (
    <H3
        renderAs="div"
        className={classes(styles.heading, className)}
        {...props}
    >
        {children}
    </H3>
);

export const H3 = ({ children, className, ...props }) => (
    <EdenH3 className={classes(styles.heading, className)} {...props}>
        {children}
    </EdenH3>
);

export const Title4 = ({ children, className, ...props }) => (
    <H4
        renderAs="div"
        className={classes(styles.heading, className)}
        {...props}
    >
        {children}
    </H4>
);

export const H4 = ({ children, className, ...props }) => (
    <EdenH4 className={classes(styles.heading, className)} {...props}>
        {children}
    </EdenH4>
);

export const Title5 = ({ children, className, ...props }) => (
    <H5
        renderAs="div"
        className={classes(styles.heading, className)}
        {...props}
    >
        {children}
    </H5>
);

export const H5 = ({ children, className, ...props }) => (
    <EdenH5 className={classes(styles.heading, className)} {...props}>
        {children}
    </EdenH5>
);

export const Title6 = ({ children, className, ...props }) => (
    <H6
        renderAs="div"
        className={classes(styles.heading, className)}
        {...props}
    >
        {children}
    </H6>
);

export const H6 = ({ children, className, ...props }) => (
    <EdenH6 className={classes(styles.heading, className)} {...props}>
        {children}
    </EdenH6>
);
