import { useContext, useMemo } from "react";
import styles from "./ErrorPanel.css";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { asyncCheckLogin } from "../../actions/system";
import analytics from "../../../util/analytics";
import {
    useMountEffect,
    useSelectErrorByStatus,
} from "../../../util/custom-hooks";
import Frame from "../Frame";
import GeneralContext from "../GeneralContext";

const ErrorPanel = ({
    className,
    crossLinkMode,
    status,
    frameClass,
    id,
    lang,
    selectErrorByStatus,
}) => {
    const { title, desc, statusCode } = selectErrorByStatus(status);

    useMountEffect(() => {
        !crossLinkMode &&
            analytics.firePageViewEvent(asyncCheckLogin, {
                page: {
                    info: {
                        language: lang,
                    },
                    category: {
                        primary: "error",
                        type: status,
                    },
                },
            });
    });

    return (
        <Frame type="narrowReader" id={id} className={frameClass}>
            <section className={className}>
                <span className={styles.pageTitle}>{title}</span>
                <p id="articleBody" className={styles.articleBody}>
                    {desc}
                </p>
                {statusCode && <p>{statusCode}</p>}
            </section>
        </Frame>
    );
};

const ErrorPanelContainer = (props) => {
    const selectErrorByStatus = useSelectErrorByStatus();
    const { crossLinkMode, lang } = useContext(GeneralContext);

    const dispatch = useDispatch();
    const actions = useMemo(
        () => bindActionCreators({ asyncCheckLogin }, dispatch),
        [dispatch]
    );

    return (
        <ErrorPanel
            crossLinkMode={crossLinkMode}
            lang={lang}
            selectErrorByStatus={selectErrorByStatus}
            {...actions}
            {...props}
        />
    );
};

export default ErrorPanelContainer;
