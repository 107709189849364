import styles from "../Image.css";
import classes from "classnames";
import handleClickAndEnterKey from "../../../../util/handle-click-and-enter-key";
import { Image as ImageIcon } from "@churchofjesuschrist/eden-icons";
import { Text3, Text4 } from "@churchofjesuschrist/eden-text";

const DefaultPlaceholder = ({
    alt = "",
    className = "",
    handleShow,
    height,
    imageText,
    inline,
    width,
}) => {
    const isIcon = className.includes("icon");

    const Figure = inline ? "span" : "figure";
    const Container = inline ? "span" : "div";
    const Text = isIcon ? Text4 : Text3;

    return (
        <Figure
            className={classes(className, styles.loadImageItem)}
            onClick={handleClickAndEnterKey(handleShow)}
            onKeyPress={handleClickAndEnterKey(handleShow)}
            title={alt}
        >
            <img width={width} height={height} alt="" />
            <Container className={styles.container}>
                {inline ? (
                    <ImageIcon size="1em" />
                ) : (
                    <div className={styles.innerFlex}>
                        {!isIcon && <ImageIcon size="1.5rem" />}
                        <div className={styles.description}>
                            <Text className={styles.i18nString}>
                                {imageText}
                            </Text>
                            <Text className={styles.title}>{alt}</Text>
                        </div>
                    </div>
                )}
            </Container>
        </Figure>
    );
};

export default DefaultPlaceholder;
